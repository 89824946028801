import * as React from 'react';
import { useState, useEffect } from 'react';
import { ResponsiveBar } from '@nivo/bar';
import { ResponsivePie } from '@nivo/pie';
import moment from "moment";
import './ToiletFrequency.css';
import { useMediaQuery } from 'react-responsive';
import DateSelectionSlider from '../dateSelectionSlider/Slider';
import DateSelectorInput from '../dateSelectionSlider/DateSelectorInput';
import { authProvider } from '../../authProvider';
export function ToiletFrequency(props) {
    var _a;
    moment.locale('en-gb');
    const maximumDays = 30;
    const [toiletFreq, setToiletFreq] = useState([]);
    let temp = moment();
    const [startDate, setStartDate] = useState(temp);
    const [endDate, setEndDate] = useState(temp);
    const [lowerSliderChange, setLowerSliderChange] = useState(0);
    const [upperSliderChange, setUpperSliderChange] = useState(0);
    const [isSliderLocked, setSliderLocked] = useState(false);
    useEffect(() => {
        async function fetchData() {
            if (startDate === null || endDate === null || endDate.diff(startDate, 'days') > maximumDays * 1.1 || startDate >= endDate) {
                return;
            }
            const res = await fetch(`/api/toiletFrequency?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}&acsis=${props.acsisID}`, { headers: { Authorization: 'Bearer ' + (await authProvider.getAccessToken()).accessToken } });
            res
                .json()
                .then((res) => {
                let toiletFreq = [];
                for (var i = 0; i < res.length; i++) {
                    let dateTime = moment(res[i].dateTime);
                    if (toiletFreq.length > 0 && toiletFreq[toiletFreq.length - 1].date === dateTime.format('DD/MM/YY')) {
                        if (dateTime.hours() < 6 || dateTime.hours() > 10) {
                            toiletFreq[toiletFreq.length - 1].nightCount++;
                        }
                        else {
                            toiletFreq[toiletFreq.length - 1].dayCount++;
                        }
                    }
                    else {
                        if (dateTime.hours() < 6 || dateTime.hours() > 10) {
                            toiletFreq.push({
                                date: dateTime.format('DD/MM/YY'),
                                dayCount: 0,
                                nightCount: 1
                            });
                        }
                        else {
                            toiletFreq.push({
                                date: dateTime.format('DD/MM/YY'),
                                dayCount: 1,
                                nightCount: 0
                            });
                        }
                    }
                }
                setToiletFreq(toiletFreq);
            })
                .catch(err => console.warn(err));
        }
        fetchData();
    }, [startDate, endDate]);
    let graphData = null;
    let pieChart = null;
    let total = [];
    let min, max, avg;
    if (toiletFreq && toiletFreq.length > 0) {
        let pieData = [];
        let keys = [];
        if (toiletFreq[0].dayCount != null && toiletFreq[0].nightCount != null) {
            keys.push("dayCount", "nightCount");
            let dayCount = toiletFreq.reduce((v, d) => v + d.dayCount, 0);
            if (dayCount > 0) {
                pieData.push({
                    id: "Day",
                    label: "Day",
                    value: dayCount,
                    color: "hsl(119, 70%, 50%)"
                });
            }
            let nightCount = toiletFreq.reduce((v, d) => v + d.nightCount, 0);
            if (nightCount > 0) {
                pieData.push({
                    id: "Night",
                    label: "Night",
                    value: nightCount,
                    color: "hsl(193, 70%, 50%)"
                });
            }
            total = toiletFreq.map(d => d.dayCount + d.nightCount);
        }
        min = Math.round(total.reduce((v, d) => d < v ? d : v, total[0]));
        max = Math.round(total.reduce((v, d) => d > v ? d : v, total[0]));
        avg = Math.round(total.reduce((v, d) => d + v, 0) / total.length * 10) / 10;
        let colourScheme = ["#f8b195", '#355375'];
        pieChart = React.createElement(ResponsivePie, { data: pieData, margin: { top: 10, right: 10, bottom: 10, left: 10 }, colors: colourScheme, innerRadius: 0.4, padAngle: 0.7, cornerRadius: 3, enableRadialLabels: false, enableSlicesLabels: false, legends: [{
                    anchor: 'top-left',
                    direction: 'column',
                    justify: false,
                    translateX: 0,
                    translateY: 0,
                    itemWidth: 100,
                    itemHeight: 20,
                    itemsSpacing: 0,
                    symbolSize: 20,
                    itemDirection: 'left-to-right'
                },
                {
                    anchor: 'top-left',
                    direction: 'column',
                    justify: false,
                    translateX: 0,
                    translateY: 0,
                    itemWidth: 100,
                    itemHeight: 20,
                    itemsSpacing: 0,
                    symbolSize: 20,
                    itemDirection: 'left-to-right'
                }] });
        graphData = React.createElement(ResponsiveBar, { data: toiletFreq, indexBy: "date", keys: keys, margin: { top: 20, right: 20, bottom: 50, left: 50 }, axisBottom: { legend: "Date", legendOffset: 40, format: d => moment(d, 'DD/MM/YY').format('DD/MM'), tickRotation: -50, legendPosition: "middle" }, axisLeft: { legend: "Toilet usage frequency", legendOffset: -30 }, colors: colourScheme, tooltip: d => (React.createElement("span", null, (d.id === 'dayCount' ? 'Day' : 'Night') + ': ' + d.value + ' hours')), labelTextColor: (d) => {
                if (d.color !== "#f8b195") {
                    return 'white';
                }
                else {
                    return 'black';
                }
            } });
    }
    return (React.createElement("div", { className: "dashboard-toilet-frequency" },
        React.createElement("div", { className: "dashboardFalls__header shadow corners" },
            React.createElement("div", { className: "dashboardFalls__titleTextContainer" },
                React.createElement("h1", null,
                    "Toilet",
                    React.createElement("br", null),
                    "Frequency")),
            React.createElement("div", { className: "dashboardFalls__sliderContainer", id: "sliderContainer" },
                useMediaQuery({
                    query: '(max-width: 1050px)'
                }) === false ?
                    React.createElement(DateSelectionSlider, { lowerSliderPos: startDate, setLowerSliderPos: setStartDate, upperSliderPos: endDate, setUpperSliderPos: setEndDate, dataInputObject: props.sliderData, minimumDays: 15, maximumDays: maximumDays, pingLowerUpdate: lowerSliderChange, pingUpperUpdate: upperSliderChange })
                    :
                        null,
                React.createElement("div", { className: "dashboardFalls__entryContainer" },
                    React.createElement(DateSelectorInput, { sliderPos: startDate, setSliderPos: setStartDate, label: "Start DateTime", pingUpdate: setLowerSliderChange, pingCounter: lowerSliderChange, dataInputObject: props.sliderData }),
                    React.createElement(DateSelectorInput, { sliderPos: endDate, setSliderPos: setEndDate, label: "End DateTime", pingUpdate: setUpperSliderChange, pingCounter: upperSliderChange, dataInputObject: props.sliderData })),
                isSliderLocked === true ?
                    React.createElement("div", { className: "dashboardFalls__lock corners", style: { height: (_a = document.getElementById('sliderContainer')) === null || _a === void 0 ? void 0 : _a.clientHeight } })
                    :
                        null)),
        React.createElement("div", { className: "dashboard-toilet-frequency__content", style: { height: "500px" } },
            React.createElement("div", { className: "dashboard-toilet-frequency__barchart shadow corners" },
                React.createElement("div", { style: { position: 'relative', width: '100%', height: '100%' } },
                    React.createElement("div", { style: { position: 'absolute', width: '100%', height: '100%' } }, toiletFreq != null && toiletFreq.length === 0 ?
                        React.createElement("h3", null, "No data was found for the provided time period")
                        : React.createElement(React.Fragment, null, graphData)))),
            React.createElement("div", { className: "dashboard-toilet-frequency__piechart shadow corners" },
                React.createElement("div", { style: { position: 'relative', width: '100%', height: '100%' } },
                    React.createElement("div", { style: { position: 'absolute', width: '100%', height: '100%' } }, toiletFreq != null && toiletFreq.length === 0 ?
                        React.createElement("h3", null, "No data was found for the provided time period")
                        : React.createElement(React.Fragment, null, pieChart)))),
            React.createElement("div", { className: "dashboard-toilet-frequency__stats-panel shadow corners" }, toiletFreq != null && toiletFreq.length === 0 ?
                React.createElement("h3", null, "No data was found for the provided time period")
                :
                    React.createElement("span", null,
                        "Fewest toilet visits within 24 hours:",
                        React.createElement("br", null),
                        React.createElement("b", null, min))),
            React.createElement("div", { className: "dashboard-toilet-frequency__stats-panel shadow corners" }, toiletFreq != null && toiletFreq.length === 0 ?
                React.createElement("h3", null, "No data was found for the provided time period")
                :
                    React.createElement("span", null,
                        "Average toilet visits within 24 hours:",
                        React.createElement("br", null),
                        React.createElement("b", null, avg))),
            React.createElement("div", { className: "dashboard-toilet-frequency__stats-panel shadow corners" }, toiletFreq != null && toiletFreq.length === 0 ?
                React.createElement("h3", null, "No data was found for the provided time period")
                :
                    React.createElement("span", null,
                        "Most toilet visits within 24 hours:",
                        React.createElement("br", null),
                        React.createElement("b", null, max))))));
}
export default ToiletFrequency;
