import * as React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { toast } from 'react-toastify';
import './ReferralForm.css';
import SingleLineWrappedinput from './components/basic/singleLineWrappedTextInput/singleLineWrappedTextInput';
import SingleLineWrappedDropDown from './components/basic/singleLineWrappedDropDown/singleLineWrappedDropDown';
import ConsentButton from './components/basic/consentButton/consentButton';
export function ServiceUserReferral(props) {
    const [submit, setSubmit] = useState(false);
    const [validity, setValidity] = useState(null);
    const [consentProvided, setConsentProvided] = useState(false);
    // Referrer details
    const [workerName, setWorkerName] = useState("");
    const [consentDate, setConsentDate] = useState("");
    const [workerOrg, setWorkerOrg] = useState("");
    const [workerContactNumber, setWorkerContactNumber] = useState("");
    const [workerEmail, setWorkerEmail] = useState("");
    // Residents details
    const [name, setName] = useState("");
    const [acsis, setacsis] = useState("");
    const [dob, setDob] = useState("");
    const [addressl1, setAddressl1] = useState("");
    const [addressl2, setAddressl2] = useState("");
    const [addressPostcode, setAddressPostcode] = useState("");
    const [contactNumber, setContactNumber] = useState("");
    const [nokName, setNokName] = useState("");
    const [nokContactNumber, setNokContactNumber] = useState("");
    const [nokEmail, setNokEmail] = useState("");
    const [friendOrFamilyBool, setFriendOrFamilyBool] = useState("");
    const [fofName, setFofName] = useState("");
    const [fofContactNumber, setFofContactNumber] = useState("");
    const [fofEmail, setFofEmail] = useState("");
    const [pets, setPets] = useState("");
    // Personal environment
    const [existingCommunityAlarm, setExistingCommunityAlarm] = useState('');
    const [providerOfExistingTelecare, setProviderOfExistingTelecare] = useState("");
    const [existingKeySafe, setExistingKeySafe] = useState('');
    const [ethnicity, setEthnicity] = useState('');
    const [sex, setSex] = useState('');
    useEffect(() => {
        async function submitForm() {
            if (submit === true) {
                if (friendOrFamilyBool === "Yes, the resident has a family or friend carer that will respond to alerts") {
                    if (fofName == "" || fofContactNumber == "" || fofEmail == "") {
                        toast.error('Please enter all required information before trying to submit');
                        setSubmit(false);
                        return;
                    }
                }
                if (existingCommunityAlarm === "Yes" && providerOfExistingTelecare === "") {
                    toast.error('Please enter all required information before trying to submit');
                    setSubmit(false);
                    return;
                }
                let successFullParse = false;
                try {
                    let x = parseInt(acsis);
                    if (x) {
                        props.setACSIS(x);
                        successFullParse = true;
                    }
                }
                catch (_a) {
                    console.log('Could not parse acsis');
                    setSubmit(false);
                    return;
                }
                if (!successFullParse) {
                    toast.error('Please check the ACSIS reference you have provided, it should be a number');
                    setSubmit(false);
                    return;
                }
                let messageString = '';
                messageString += `<span><b>Referrer name : </b> ${workerName}</span><br />`;
                messageString += `<span><b>Referral date : </b> ${consentDate}</span><br />`;
                messageString += `<span><b>Referrer contact number : </b> ${workerContactNumber}</span><br />`;
                messageString += `<span><b>Referrer email : </b> ${workerEmail}</span><br />`;
                messageString += `<span><b>Referrer organisation : </b> ${workerOrg}</span><br />`;
                messageString += `<span><b>Name : </b> ${name}</span><br />`;
                messageString += `<span><b>Acsis : </b> ${acsis}</span><br />`;
                messageString += `<span><b>DoB : </b> ${dob}</span><br />`;
                messageString += `<span><b>Sex : </b> ${sex}</span><br />`;
                messageString += `<span><b>Ethnicity : </b> ${ethnicity}</span><br />`;
                messageString += `<span><b>Acsis : </b> ${acsis}</span><br />`;
                messageString += `<span><b>Address line 1 : </b> ${addressl1}</span><br />`;
                messageString += `<span><b>Address line 2 : </b> ${addressl2}</span><br />`;
                messageString += `<span><b>Postcode : </b> ${addressPostcode}</span><br />`;
                messageString += `<span><b>Contact Number : </b> ${contactNumber}</span><br />`;
                messageString += `<span><b>Next of Kin Name : </b> ${nokName}</span><br />`;
                messageString += `<span><b>Next of Kin Contact number : </b> ${nokContactNumber}</span><br />`;
                messageString += `<span><b>Next of Kin Email : </b> ${nokEmail}</span><br />`;
                messageString += `<span><b>Friend or Family responder : </b> ${friendOrFamilyBool}</span><br />`;
                messageString += `<span><b>Friend or Family Name : </b> ${fofName}</span><br />`;
                messageString += `<span><b>Friend or Family Contact number : </b> ${fofContactNumber}</span><br />`;
                messageString += `<span><b>Friend or Family Email: </b> ${fofEmail}</span><br />`;
                messageString += `<span><b>Key Safe : </b> ${existingKeySafe}</span><br />`;
                messageString += `<span><b>Pets : </b> ${pets}</span><br />`;
                messageString += `<span><b>Existing community alarm : </b> ${existingCommunityAlarm}</span><br />`;
                messageString += `<span><b>Name of telecare provider : </b> ${providerOfExistingTelecare}</span><br />`;
                let requestBody = {
                    MessageString: messageString,
                    MessageHTML: messageString,
                    Targets: ['assistivetechnology@hertfordshire.gov.uk'],
                    Subject: `${acsis} - Referral Form`,
                    ACSIS: parseInt('' + acsis)
                };
                let payload = {
                    Payload: requestBody
                };
                await fetch('/api/referralForm', {
                    method: 'POST',
                    body: JSON.stringify(requestBody),
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }).then((res) => {
                    if (res.ok) {
                        toast.success('Referral form sent successfully');
                        props.setReferralState(1);
                        return;
                    }
                    toast.error('Unable to submit referral form, please try again later');
                }).catch((error) => {
                    console.log(error);
                    toast.error('Unable to submit referral form, please try again later');
                });
                setSubmit(false);
            }
        }
        submitForm();
    }, [submit]);
    return (React.createElement("div", { className: "window__referral" },
        React.createElement("div", { className: "referral__referralContainer corners shadow" },
            React.createElement("div", { className: "referral__headlineContainer" },
                React.createElement("h1", null, "Assistive Technology Referral Form")),
            React.createElement("div", { className: "referral__contentContainer" },
                React.createElement("div", { className: "referral__consentSection" },
                    React.createElement("div", { className: "referral__consentDocument corners" },
                        React.createElement("h3", { className: "referral__consentDocumentText" }, " In order to be part of the Assistive technology study residents must have capacity to consent."),
                        React.createElement("h3", { className: "referral__consentDocumentText" }, " Please confirm that this resident has been given information regarding the study and an explanation on how the sensor will collect information regarding their daily lives. They need to understand that activity data will be collected and analysed as part of this study."),
                        React.createElement("h3", { className: "referral__consentDocumentText" }, " Please confirm that this resident lives in  East Herts, Broxbourne or Stevenage. ")),
                    React.createElement(SingleLineWrappedinput, { entryName: "Full name of referrer: ", placeholderText: "", value: workerName, setValue: setWorkerName, styleProp: { marginBottom: '10px' }, editable: consentProvided !== true }),
                    React.createElement(SingleLineWrappedinput, { entryName: "Date of referral: ", placeholderText: "DD/MM/YYYY", value: consentDate, setValue: setConsentDate, editable: consentProvided !== true }),
                    React.createElement(ConsentButton, { setConsent: setConsentProvided, dependancies: [workerName, consentDate], consent: consentProvided, consentText: ['I confirm that the Resident has capacity to consent to being part of the study and has been given information regarding the technology being provided', 'I confirm that the Resident lives in East Herts, Dacorum, Hertsmere Broxbourne or Stevenage.'] }),
                    React.createElement("h3", { className: "referral__consentDocumentText" },
                        "Should the resident's capacity to the pilot change, please inform the AT team at the following email address: ",
                        React.createElement("br", null),
                        " AssistiveTechnology@hertfordshire.gov.uk")),
                consentProvided === true ?
                    React.createElement("div", { className: "referral__formSection" },
                        React.createElement("div", { className: "referral__spacerline" }),
                        React.createElement("h2", { className: "referral__consentDocumentText" }, "Referrer Details"),
                        React.createElement("div", { className: "referral__formRow" },
                            React.createElement(SingleLineWrappedinput, { entryName: "Contact number: ", placeholderText: "", value: workerContactNumber, setValue: setWorkerContactNumber, styleProp: { marginBottom: '10px' }, editable: validity !== true }),
                            React.createElement(SingleLineWrappedinput, { entryName: "Organisation / Team: ", placeholderText: "", value: workerOrg, setValue: setWorkerOrg, styleProp: { marginBottom: '10px' }, editable: validity !== true })),
                        React.createElement("div", { className: "referral__formRow" },
                            React.createElement(SingleLineWrappedinput, { entryName: "Email address: ", placeholderText: "", value: workerEmail, setValue: setWorkerEmail, styleProp: { marginBottom: '10px' }, editable: validity !== true })),
                        React.createElement("div", { className: "referral__spacerline" }),
                        React.createElement("h2", { className: "referral__consentDocumentText" }, "Basic Resident Information"),
                        React.createElement("div", { className: "referral__formRow" },
                            React.createElement(SingleLineWrappedinput, { entryName: "Name: ", placeholderText: "", value: name, setValue: setName, styleProp: { marginBottom: '10px' }, editable: validity !== true }),
                            React.createElement(SingleLineWrappedinput, { entryName: "Acsis reference: ", placeholderText: "", value: acsis, setValue: setacsis, styleProp: { marginBottom: '10px' }, editable: validity !== true })),
                        React.createElement("div", { className: "referral__formRow" },
                            React.createElement(SingleLineWrappedinput, { entryName: "Date of birth: ", placeholderText: "", value: dob, setValue: setDob, styleProp: { marginBottom: '10px' }, editable: validity !== true }),
                            React.createElement(SingleLineWrappedinput, { entryName: "Contact number: ", placeholderText: "", value: contactNumber, setValue: setContactNumber, styleProp: { marginBottom: '10px' }, editable: validity !== true })),
                        React.createElement("div", { className: "referral__formRow" },
                            React.createElement(SingleLineWrappedDropDown, { entryName: "Sex: ", value: sex, setValue: setSex, styleProp: { marginBottom: '10px' }, options: [
                                    'select one',
                                    'Male',
                                    'Female',
                                    'Transgender',
                                    "Don't know",
                                    'Prefer not to say'
                                ] }),
                            React.createElement(SingleLineWrappedDropDown, { entryName: "Ethnicity: ", value: ethnicity, setValue: setEthnicity, styleProp: { marginBottom: '10px' }, options: [
                                    'select one',
                                    'White (English, Scottish, NI, Welsh)',
                                    'White (Irish)',
                                    'White (Gypsy or Irish Traveller)',
                                    'Any other white background',
                                    'Mixed white and black Caribbean',
                                    'Mixed white and black African',
                                    'Mixed white and Asian',
                                    'Asian, Asian British, Indian',
                                    'Asian, Asian British, Pakistani',
                                    'Asian, Asian British, Bangladeshi',
                                    'Asian, Asian British, Chinese',
                                    'Any other Asian background',
                                    'Black African Caribbean, black British African',
                                    'Black African Caribbean, black British Caribbean',
                                    'Any other black African background',
                                    'Other ethnic group Arab',
                                    'Other ethnic group',
                                    "Don't know",
                                    'Prefernot to say',
                                    'test',
                                ] })),
                        React.createElement("div", { className: "referral__spacerline", style: { marginTop: '40px', marginBottom: '25px' } }),
                        React.createElement("h2", { className: "referral__consentDocumentText" }, " Resident Address"),
                        React.createElement("div", { className: "referral__formRow" },
                            React.createElement(SingleLineWrappedinput, { entryName: "Address line 1: ", placeholderText: "", value: addressl1, setValue: setAddressl1, styleProp: { marginBottom: '10px' }, editable: validity !== true })),
                        React.createElement("div", { className: "referral__formRow" },
                            React.createElement(SingleLineWrappedinput, { entryName: "Address line 2: ", placeholderText: "", value: addressl2, setValue: setAddressl2, styleProp: { marginBottom: '10px' }, editable: validity !== true }),
                            React.createElement(SingleLineWrappedinput, { entryName: "Postcode: ", placeholderText: "", value: addressPostcode, setValue: setAddressPostcode, styleProp: { marginBottom: '10px' }, editable: validity !== true })),
                        React.createElement("div", { className: "referral__spacerline", style: { marginTop: '40px', marginBottom: '25px' } }),
                        React.createElement("h2", { className: "referral__consentDocumentText" }, "Next of Kin Information"),
                        React.createElement("div", { className: "referral__formRow" },
                            React.createElement(SingleLineWrappedinput, { entryName: "Name: ", placeholderText: "", value: nokName, setValue: setNokName, styleProp: { marginBottom: '10px' }, editable: validity !== true }),
                            React.createElement(SingleLineWrappedinput, { entryName: "Contact number: ", placeholderText: "", value: nokContactNumber, setValue: setNokContactNumber, styleProp: { marginBottom: '10px' }, editable: validity !== true })),
                        React.createElement("div", { className: "referral__formRow" },
                            React.createElement(SingleLineWrappedinput, { entryName: "Email: ", placeholderText: "", value: nokEmail, setValue: setNokEmail, styleProp: { marginBottom: '10px' }, editable: validity !== true })),
                        React.createElement("div", { className: "referral__spacerline", style: { marginTop: '40px', marginBottom: '25px' } }),
                        React.createElement("h2", { className: "referral__consentDocumentText" }, "Family or friend Carer"),
                        React.createElement("h3", { className: "referral__consentDocumentText" }, "Does the resident have a family/friend contact that will respond to alerts? "),
                        React.createElement("div", { className: "referral__formRow" },
                            React.createElement(SingleLineWrappedDropDown, { entryName: "Familty/Friend contact: ", value: friendOrFamilyBool, setValue: setFriendOrFamilyBool, styleProp: { marginBottom: '10px' }, options: [
                                    'select one',
                                    'Yes, the resident has a family or friend carer that will respond to alerts',
                                    'No, the resident does not have a familty or friend carer that will respond to alerts',
                                ] }),
                            React.createElement(SingleLineWrappedinput, { entryName: "Name: ", placeholderText: "", value: fofName, setValue: setFofName, styleProp: friendOrFamilyBool === "Yes, the resident has a family or friend carer that will respond to alerts" ? { marginBottom: '10px' } : { marginBottom: '10px', opacity: 0.3 }, editable: validity !== true })),
                        React.createElement("div", { className: "referral__formRow" },
                            React.createElement(SingleLineWrappedinput, { entryName: "Email: ", placeholderText: "", value: fofEmail, setValue: setFofEmail, styleProp: friendOrFamilyBool === "Yes, the resident has a family or friend carer that will respond to alerts" ? { marginBottom: '10px' } : { marginBottom: '10px', opacity: 0.3 }, editable: validity !== true }),
                            React.createElement(SingleLineWrappedinput, { entryName: "Contact number: ", placeholderText: "", value: fofContactNumber, setValue: setFofContactNumber, styleProp: friendOrFamilyBool === "Yes, the resident has a family or friend carer that will respond to alerts" ? { marginBottom: '10px' } : { marginBottom: '10px', opacity: 0.3 }, editable: validity !== true })),
                        React.createElement("div", { className: "referral__spacerline", style: { marginTop: '40px', marginBottom: '25px' } }),
                        React.createElement("h2", { className: "referral__consentDocumentText" }, "Personal Environment"),
                        React.createElement("div", { className: "referral__formRow" },
                            React.createElement(SingleLineWrappedDropDown, { entryName: "Existing community alarm: ", value: existingCommunityAlarm, setValue: setExistingCommunityAlarm, styleProp: { marginBottom: '10px' }, options: [
                                    'select one',
                                    'Yes',
                                    'No',
                                    'Unknown'
                                ] }),
                            React.createElement(SingleLineWrappedinput, { entryName: "Provider of existing telecare: ", placeholderText: "", value: providerOfExistingTelecare, setValue: setProviderOfExistingTelecare, styleProp: existingCommunityAlarm === "Yes" ? { marginBottom: '10px' } : { marginBottom: '10px', opacity: 0.3 }, editable: validity !== true })),
                        React.createElement("div", { className: "referral__formRow" },
                            React.createElement(SingleLineWrappedinput, { entryName: "Pets: ", placeholderText: "", value: pets, setValue: setPets, styleProp: { marginBottom: '10px' }, editable: validity !== true }),
                            React.createElement(SingleLineWrappedDropDown, { entryName: "Existing key safe: ", value: existingKeySafe, setValue: setExistingKeySafe, styleProp: { marginBottom: '10px' }, options: [
                                    'select one',
                                    'Yes',
                                    'No',
                                    'Unknown'
                                ] })),
                        React.createElement(ConsentButton, { setConsent: setSubmit, consent: submit, consentText: ["Submit"], dependancies: [
                                name,
                                acsis,
                                dob,
                                addressl1,
                                addressl2,
                                addressPostcode,
                                contactNumber,
                                nokEmail,
                                nokContactNumber,
                                nokName,
                                friendOrFamilyBool,
                                existingCommunityAlarm,
                                existingKeySafe,
                                ethnicity,
                                sex,
                                workerOrg,
                                workerContactNumber,
                                workerEmail
                            ] }))
                    : null))));
}
export default ServiceUserReferral;
